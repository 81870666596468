import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RestauranteBotones from "../components/RestauranteBotones.js";
import { fetchUserToken } from "../utils/AuthToken.js";
import ActivarResto from "../components/ActivarResto.js";
import { DateTime } from 'luxon';

import {
  Coin,
  CupFill,
  CupHotFill,
  Eye,
  EyeSlash,
  PersonCircle,
  StarFill,
} from "react-bootstrap-icons";
import Chart from "react-apexcharts";

function RestauranteDashboard({ token }) {
  const [isVisible, setIsVisible] = useState(true);
  const [userToken, setUserToken] = useState(null);
  const [weeklyData, setWeeklyData] = useState([]);
  const [groupedPayments, setGroupedPayments] = useState({});
  const [totalPayments, setTotalPayments] = useState(0);
  const [isAmountVisible, setIsAmountVisible] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [activeSubButton, setActiveSubButton] = useState(null);
  const [mesaDetails, setMesaDetails] = useState({});
  const [mozoDetails, setMozoDetails] = useState({});
  const [expandedGroups, setExpandedGroups] = useState({});
  const [cuentaActiva, setCuentaActiva] = useState(false);
  const [cuitRestaurante, setCuitRestaurante] = useState("");
  

  const timeZone = 'America/Argentina/Buenos_Aires'; // Zona horaria para GMT-3

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const getUserToken = async () => {
      setTimeout(async () => {
        try {
          const data = await fetchUserToken();
          setUserToken(data);
          const isCuentaActiva = data.activada;
          setCuitRestaurante(data.cuit);
          setCuentaActiva(isCuentaActiva);
          console.log("CUENTA ACTIVADA:", isCuentaActiva);
          if (isCuentaActiva === false) {
            setIsModalOpen(true);
          }
        } catch (error) {
          console.error("Error fetching user token:", error);
        }
      }, 1000);
    };

    window.addEventListener("load", getUserToken);

    return () => {
      window.removeEventListener("load", getUserToken);
    };

    getUserToken();
  }, []);

  // esta jodiendo, despues lo arreglo
  //   console.log(userToken);
  console.log("Estado de cuentaActiva:", cuentaActiva);

  const checkVisibility = () => {
    const elements = document.getElementsByClassName("flip-container");
    Array.from(elements).forEach((element) => {
      const rect = element.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (isVisible) {
        element.classList.add("hover");
      } else {
        element.classList.remove("hover");
      }
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      checkVisibility();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    checkVisibility();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    setActiveSubButton(null);
  };

  const handleSubButtonClick = (button) => {
    setActiveSubButton(button);
  };


  const formatDate = (dateString) => {
    // Convertir la cadena de fecha de MongoDB a un objeto Date
    const mongoDate = new Date(dateString);
    
    // Crear un DateTime de Luxon desde el objeto Date y ajustar la zona horaria
    const dt = DateTime.fromJSDate(mongoDate, { zone: 'UTC' }).minus({ hours: 3 }).setZone(timeZone);
    
    // Obtener la fecha y hora ajustadas
    const paymentDate = dt.toJSDate();
    const currentDate = DateTime.now().setZone(timeZone).toJSDate();
    
    // Extraer día y mes para comparar
    const paymentDay = paymentDate.getDate();
    const paymentMonth = paymentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    
    if (paymentDay === currentDay && paymentMonth === currentMonth) {
      return "Hoy";
    } else if (paymentDay === currentDay - 1 && paymentMonth === currentMonth) {
      return "Ayer";
    } else {
      return DateTime.fromJSDate(paymentDate).toFormat("d MMMM yyyy");
    }
  };

  useEffect(() => {
    if (!token) {
      console.error("No se encontró el token");
      navigate("/");
      return;
    }

    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 600);

    return () => clearTimeout(timeout);
  }, [token, navigate]);

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        const fetchWeeklyData = (await import("../components/YearlyData.js"))
          .default;
        const data = await fetchWeeklyData(token, navigate);
        if (data && data.propinas_año_actual) {
          if (process.env.NODE_ENV === "development") {
            console.log(data.propinas_año_actual);
          }
          setWeeklyData(data.propinas_año_actual);
        }
      };
      fetchData();
    }
  }, [token, navigate]);

  useEffect(() => {
    if (weeklyData.length > 0) {
      const filterData = () => {
        let filtered = [];
        const now = new Date();

        if (activeButton === "Tiempo") {
          if (activeSubButton === "UltimaSemana") {
            const weekAgo = new Date();
            weekAgo.setDate(now.getDate() - 7);
            filtered = weeklyData.filter(
              (payment) =>
                new Date(payment.propina.hora_aprovacion_pago) >= weekAgo
            );
          } else if (activeSubButton === "UltimaMes") {
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            filtered = weeklyData.filter(
              (payment) =>
                new Date(payment.propina.hora_aprovacion_pago) >= startOfMonth
            );
          } else if (activeSubButton === "UltimoAnio") {
            const yearStart = new Date(now.getFullYear(), 0, 1);
            filtered = weeklyData.filter(
              (payment) =>
                new Date(payment.propina.hora_aprovacion_pago) >= yearStart
            );
          } else {
            filtered = weeklyData;
          }
        } else {
          filtered = weeklyData;
        }

        return filtered;
      };

      const filteredData = filterData();

      let groupedData = {};
      let mesaDetails = {};
      let mozoDetails = {}; // Nuevo objeto para almacenar detalles del mozo

      if (activeButton === "Mesa") {
        groupedData = filteredData.reduce((acc, data) => {
          const mesa = data.propina.mesa;
          if (!acc[mesa]) {
            acc[mesa] = [];
          }
          acc[mesa].push(data.propina);
          return acc;
        }, {});

        Object.keys(groupedData).forEach((mesa) => {
          const payments = groupedData[mesa];
          const mozoCount = {};
          let highestMonto = 0;
          let totalCalificacion = 0;
          let totalMonto = 0;

          payments.forEach((payment) => {
            const mozo = payment.Mozo.nombre;
            if (!mozoCount[mozo]) {
              mozoCount[mozo] = 0;
            }
            mozoCount[mozo] += 1;

            highestMonto = Math.max(highestMonto, parseFloat(payment.monto));
            totalCalificacion += parseFloat(payment.calificacion);
            totalMonto += parseFloat(payment.monto);
          });

          const averageMonto = totalMonto / payments.length;
          const averageCalificacion = totalCalificacion / payments.length;
          const mostFrequentMozo = Object.keys(mozoCount).reduce((a, b) =>
            mozoCount[a] > mozoCount[b] ? a : b
          );

          mesaDetails[mesa] = {
            mostFrequentMozo,
            highestMonto,
            averageCalificacion,
            averageMonto,
            photo: payments[0].Mozo.photo || "profile_she.jpg",
          };
        });
      } else if (activeButton === "Mozo") {
        groupedData = filteredData.reduce((acc, data) => {
          const mozoName = `${data.propina.Mozo.nombre} ${data.propina.Mozo.apellido}`;
          if (!acc[mozoName]) {
            acc[mozoName] = [];
          }
          acc[mozoName].push(data.propina);
          return acc;
        }, {});

        Object.keys(groupedData).forEach((mozoName) => {
          const payments = groupedData[mozoName];
          const mozoCount = {};
          let highestMonto = 0;
          let totalCalificacion = 0;
          let totalMonto = 0;

          payments.forEach((payment) => {
            const mozo = payment.Mozo.nombre;
            if (!mozoCount[mozo]) {
              mozoCount[mozo] = 0;
            }
            mozoCount[mozo] += 1;

            highestMonto = Math.max(highestMonto, parseFloat(payment.monto));
            totalCalificacion += parseFloat(payment.calificacion);
            totalMonto += parseFloat(payment.monto);
          });

          const averageMonto = totalMonto / payments.length;
          const averageCalificacion = totalCalificacion / payments.length;
          const mostFrequentMozo = Object.keys(mozoCount).reduce((a, b) =>
            mozoCount[a] > mozoCount[b] ? a : b
          );

          mozoDetails[mozoName] = {
            mostFrequentMozo,
            highestMonto,
            averageCalificacion,
            averageMonto,
            photo: payments[0].Mozo.photo || "profile_she.jpg",
          };
        });
      } else {
        groupedData = filteredData.reduce((acc, data) => {
          const dateObj = new Date(data.propina.hora_aprovacion_pago);
          let formattedDate;

          if (activeSubButton === "UltimoAnio") {
            formattedDate = dateObj.toLocaleDateString("es-ES", {
              month: "long",
              year: "numeric",
            });
          } else {
            formattedDate = formatDate(dateObj.toISOString());
          }

          if (!acc[formattedDate]) {
            acc[formattedDate] = { date: dateObj, payments: [] };
          }
          acc[formattedDate].payments.push(data.propina);
          return acc;
        }, {});
      }

      if (activeButton !== "Mesa" && activeButton !== "Mozo") {
        const groupedArray = Object.keys(groupedData).map((key) => ({
          date: groupedData[key].date,
          formattedDate: key,
          payments: groupedData[key].payments,
        }));

        groupedArray.sort((a, b) => b.date - a.date);

        const orderedGroupedData = {};
        groupedArray.forEach((group) => {
          orderedGroupedData[group.formattedDate] = group.payments;
        });

        setGroupedPayments(orderedGroupedData);
      } else {
        setGroupedPayments(groupedData);
        setMesaDetails(mesaDetails);
        setMozoDetails(mozoDetails);
      }

      const total = filteredData.reduce(
        (sum, payment) => sum + parseFloat(payment.propina.monto),
        0
      );
      setTotalPayments(
        total.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );
    }

    

  }, [weeklyData, activeButton, activeSubButton]);

  const getPeriodText = () => {
    if (activeSubButton === "UltimaSemana") {
      return "(esta semana)";
    } else if (activeSubButton === "UltimaMes") {
      return "(ultimos 30 dias)";
    } else if (activeSubButton === "UltimoAnio") {
      return "(este año)";
    } else {
      return "(este año)";
    }
  };

  const groupPaymentsByTable = (payments) => {
    const groupedPayments = new Map();
  
    payments.forEach(payment => {
      
      const mesa  = payment.propina.mesa;
      const monto =  payment.propina.monto;	
      

      if (!groupedPayments.has(mesa)) {
        groupedPayments.set(mesa, 0);
      }
      groupedPayments.set(mesa, groupedPayments.get(mesa) + monto);
    });
  
    return Array.from(groupedPayments, ([mesa, monto]) => ({ mesa: Number(mesa), monto }));
  };
  
 
  const groupedPaymentsArray = groupPaymentsByTable(weeklyData);
  

  // Función para manejar el clic en "Mostrar Todos" de un grupo específico
  const toggleExpand = (key) => {
    setExpandedGroups((prevExpanded) => ({
      ...prevExpanded,
      [key]: !prevExpanded[key], // Invertir el estado de expandir o contraer
    }));
  };

  return (
    <div>
      <h1 className="hidden md:block text-xl bg-pink-500 text-white p-2 font-sans mt-0 pt-1 pl-10 mb-4 ">
        Su Restaurante
      </h1>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: isVisible ? 1000 : -1000,
          backgroundColor: "#FF5EA3",
          opacity: isVisible ? 1 : 0,
          transition: "opacity .3s, z-index 0s 0.3s",
        }}
      />
      <div className="w-full min-h-screen pt-3 pb-10 md:bg-none bg-mobile-gradient">
        <div className="flex items-left justify-left text-white w-11/12 mx-auto mb-2 mt-3 text-sm md:hidden">
          <PersonCircle size={22} color="#fff" className="float-left mr-2" />
          <h2>Hola {sessionStorage.getItem("nombre")}!</h2>
        </div>

        <div className="flex flex-col md:flex-row gap-4 md:w-11/12 mx-auto items-center justify-center max-w-[900px]">
          <div
            id="div1"
            className={`rounded-md w-11/12 md:w-11/12 mx-auto p-4 py-5 md:py-6 md:my-5 md:h-[120px] cursor-pointer transition-all ${
              isAmountVisible
                ? "shadow-lg shadow-pink-700 bg-white md:shadow-gray-300"
                : "bg-pink-900 text-white"
            }`}
            onClick={() => setIsAmountVisible(!isAmountVisible)}
          >
            <div className="text-3xl font-semibold font-mono flex items-center justify-center">
              {isAmountVisible ? `$${totalPayments}` : "$****"}
              {isAmountVisible ? (
                <Eye size={30} color="#333" className="ml-2" />
              ) : (
                <EyeSlash size={30} color="#fff" className="ml-2" />
              )}
            </div>
            <div className="flex items-center justify-center text-sm mt-2">
              {isAmountVisible ? (
                <Coin size={20} color="#333" className="ml-2" />
              ) : (
                <Coin size={20} color="#fff" className="ml-2" />
              )}
              &nbsp; Total de propinas {getPeriodText()}
            </div>
          </div>

          <div
            id="div2"
            className="text-center bg-white w-11/12 mx-auto rounded-lg py-3 md:p-0 md:h-[120px] my-5 shadow-lg shadow-gray-250 flex items-center justify-center"
          >
            <RestauranteBotones
              activeButton={activeButton}
              handleButtonClick={handleButtonClick}
            />
          </div>
        </div>

        <div
          className="w-11/12 pt-5 mt-2 px-5 pb-5 min-h-full mx-auto bg-white shadow-lg shadow-gray-250 rounded-lg max-w-[900px]"
          id="WeeklyReporte"
        >
          {activeButton === "Tiempo" && (
            <div className="md:flex md:justify-center xl:justify-end ">
              <div className="flex text-xs mb-5 justify-center md:w-2/3 md:max-w-[400px]">
                <button
                  className={`py-3 w-full mr-2 rounded-md transition-all duration-200 ${
                    activeSubButton === "UltimaSemana"
                      ? "text-white bg-[#198cff] !important"
                      : "bg-gray-200 hover:bg-[#e9f3fd] hover:text-[#198cff]"
                  }`}
                  onClick={() => handleSubButtonClick("UltimaSemana")}
                >
                  Esta Semana
                </button>
                <button
                  className={`px-2 py-3 mr-2 w-full rounded-md transition-all duration-200 ${
                    activeSubButton === "UltimaMes"
                      ? "text-white bg-[#198cff]"
                      : "bg-gray-200 hover:bg-[#e9f3fd] hover:text-[#198cff]"
                  }`}
                  onClick={() => handleSubButtonClick("UltimaMes")}
                >
                  Últimos 30 dias
                </button>
                <button
                  className={`py-3 w-80 rounded-md transition-all duration-200 ${
                    activeSubButton === "UltimoAnio"
                      ? "text-white bg-[#198cff]"
                      : "bg-gray-200 hover:bg-[#e9f3fd] hover:text-[#198cff]"
                  }`}
                  onClick={() => handleSubButtonClick("UltimoAnio")}
                >
                  Este Año
                </button>
              </div>
            </div>
          )}

          {Object.entries(groupedPayments).map(([key, payments], index) => (
            <div key={index}>
              {activeButton === "Mesa" && (
                <div className="md:flex">
                  <div className="coffe-cup mt-12 md:mt-[95px] mx-auto md:scale-150">
                    <span className="absolute text-white w-full text-center h-full align-baseline pt-3 text-3xl font-mono z-10">
                      {key}
                    </span>
                    <div className="smoke"></div>
                    <div className="smoke"></div>
                    <div className="smoke"></div>
                    <div className="relative">
                      <CupFill
                        size={96}
                        color="#f05e9e"
                        className="absolute mt-[-18px]"
                      />
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 border-2 mt-3 border-pink-300 rounded-md md:rounded-xl px-3 py-1 md:p-0 mb-2 shadow-md md:bg-pink-300 md:w-2/3">
                    <div className="md:p-3 md:rounded-l-xl md:rounded-r-md md:bg-white md:m-1">
                      <h3 className="text-pink-500 rounded-md p-2 text-sm mt-2">
                        Mozo más frecuente
                        <span className="bg-white rounded-md px-2 float-right text-gray-500 ">
                          {mesaDetails[key]?.mostFrequentMozo}
                        </span>
                      </h3>
                      <h3 className="text-pink-500 rounded-md p-2 text-sm mt-2">
                        Propina más alta:
                        <span className="bg-white rounded-md px-2 float-right text-gray-500  font-normal">
                          ${mesaDetails[key]?.highestMonto}
                        </span>
                      </h3>
                      <h3 className="text-pink-500 rounded-md p-2 text-sm mt-2">
                        Propina promedio:
                        <span className="bg-white rounded-md px-2 float-right text-gray-500 font-normal">
                          $
                          {mesaDetails[key]?.averageMonto !== undefined
                            ? `${mesaDetails[key]?.averageMonto.toFixed(2)}`
                            : "No hay datos"}
                        </span>
                      </h3>
                      <h3 className="text-pink-500 rounded-md p-2 text-sm mt-2">
                        Calificación promedio:
                        <span className="bg-white rounded-md px-2 float-right text-gray-500 font-normal">
                          {mesaDetails[key]?.averageCalificacion.toFixed()}
                          <StarFill className="float-right ml-1 mt-0.5" />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              )}

              {activeButton === "Mozo" && (
                <div className="flex flex-col md:flex-row items-start mb-5 gap-4">
                  <div className="flip-container hover mb-5 w-full h-[440px] md:w-[310px] md:h-[380px] xl:h-[430px]">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front rounded-xl shadow-gray-400 shadow-md bg-gradient-to-tr from-purple-600 to-blue-600 flex justify-center items-center">
                          <StarFill size={90} color="#fff" />
                        </div>

                        <div className="flip-card-back text-white rounded-xl p-4 mb-5 shadow-gray-400 shadow-md bg-gradient-to-tr from-purple-600 to-blue-600">
                          <img
                            src={mozoDetails[key]?.photo}
                            className="img-fluid rounded-lg w-full h-auto shadow"
                            alt=""
                          />
                          <h3 className="rounded-md p-0 text-2xl font-semibold mt-2">
                            {mozoDetails[key]?.mostFrequentMozo}
                          </h3>
                          <h3>
                            Mejor Propina: $
                            {mozoDetails[key]?.highestMonto.toFixed()}
                          </h3>
                          <h3>
                            Calificación Promedio:{" "}
                            {mozoDetails[key]?.averageCalificacion.toFixed(1)}
                          </h3>
                          <h3>
                            Propina Promedio: $
                            {mozoDetails[key]?.averageMonto !== undefined
                              ? mozoDetails[key]?.averageMonto.toFixed(2)
                              : "No hay datos"}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="hidden md:block md:flex-grow">
                    <div id="charts-container">
                      <div className="chart mx-auto text-center">
                        <h3 className="text-md text-gray-500">Pagos Totales</h3>
                        <h3 className="text-xl font-semibold mb-3">
                          $
                          {groupedPaymentsArray
                            .reduce((sum, payment) => sum + payment.monto, 0)
                            .toLocaleString("en-US", {
                              maximumFractionDigits: 0,
                            })}
                        </h3>

                        <Chart
                          options={{
                            chart: {
                              type: "donut",
                              events: {
                                click: function (event, chartContext, config) {
                                  const pieSliceElement = event.target;

                                  const mouseoverEvent = new Event("mouseover", {
                                    bubbles: true,
                                    cancelable: true,
                                  });
                                  pieSliceElement.dispatchEvent(mouseoverEvent);

                                  const mousemoveEvent = new MouseEvent("mousemove", {
                                    view: window,
                                    bubbles: true,
                                    cancelable: true,
                                    clientX: event.clientX,
                                    clientY: event.clientY,
                                  });
                                  pieSliceElement.dispatchEvent(mousemoveEvent);

                                  setTimeout(() => {
                                    document
                                      .querySelector(".apexcharts-tooltip")
                                      .classList.add("apexcharts-active");
                                  }, 100);
                                },
                              },
                            },
                            labels: groupedPaymentsArray.map((payment) => `Mesa ${payment.mesa}`),
                            colors: groupedPaymentsArray.map(
                              () =>
                                `#${Math.floor(Math.random() * 16777215)
                                  .toString(16)
                                  .padStart(6, "0")}`
                            ),
                            dataLabels: {
                              enabled: false,
                            },
                            legend: {
                              show: false,
                            },
                            tooltip: {
                              enabled: true,
                              style: {
                                fontSize: "22px",
                                fontFamily: "Arial",
                              },
                              formatter: (value, { series, seriesIndex, dataIndex, w }) => {
                                const mesa = groupedPaymentsArray[dataIndex].mesa;
                                const monto = groupedPaymentsArray[dataIndex].monto;
                                return `Mesa ${mesa}: $${monto.toFixed(2)}`;
                              },
                            },
                            stroke: {
                              width: 0,
                            },
                          }}
                          series={groupedPaymentsArray.map((payment) => Number(payment.monto.toFixed(2)))}
                          type="donut"
                          width="420"
                        />
                      </div>
                    </div>
                </div>

                </div>
              )}

              {activeButton !== "Mesa" && (
                <h3 className="bg-gray-100 rounded-md p-2 text-sm mt-2">
                  {key}
                </h3>
              )}
              {payments
                .sort(
                  (a, b) =>
                    new Date(b.hora_aprovacion_pago) -
                    new Date(a.hora_aprovacion_pago)
                )
                .slice(0, expandedGroups[key] ? payments.length : 5)
                .map((payment, paymentIndex) => (
                  <div key={paymentIndex}>
                    <div
                      className={`flex flex-row items-center text-sm ${
                        activeButton !== "Mesa" ? "py-5" : "py-3"
                      } ${
                        paymentIndex !== payments.length - 1
                          ? "border-b border-gray-200"
                          : ""
                      }`}
                    >
                      {activeButton !== "Mesa" && (
                        <div className="xs:w-1/6 md:w-1/6 relative md:transform md:scale-[1.25]">
                          <div className="absolute w-full h-full flex items-end justify-center text-lg text-white font-mono ml-[-3px]">
                            {payment.mesa}
                          </div>

                          <CupHotFill
                            size={42}
                            color="#f05e9e"
                            className="mx-auto"
                          />
                        </div>
                      )}

                      <div className="w-20">
                        <img
                          src={
                            activeButton === "Mesa"
                              ? mesaDetails[key]?.photo
                              : payment.Mozo.photo || "profile_she.jpg"
                          }
                          className="img-fluid rounded-full shadow w-10 h-10 md:w-12 md:h-12 mx-auto border-pink-500 border-2"
                          alt=""
                        />
                      </div>
                      <div className="w-60 text-gray-600 pl-3">
                        {payment.estado === "approved" ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                payment.estado_detallado === "accredited"
                                  ? "Propina Acreditada <span className='text-green-600'>✔</span>"
                                  : "Propina Pendiente",
                            }}
                          />
                        ) : (
                          <div>Propina pendiente</div>
                        )}

                        <div className="text-md text-gray-500 capitalize">
                          {payment.Mozo.nombre} {payment.Mozo.apellido}
                        </div>
                        <div className="text-xs text-gray-400 capitalize">
                          {payment.Restaurante}
                        </div>

                        {activeSubButton === "UltimoAnio" && (
                          <div className="text-xs text-gray-400">
                            {new Date(
                              payment.hora_aprovacion_pago
                            ).toLocaleDateString("es-AR", {
                              month: "long",
                              day: "numeric",
                            })}
                          </div>
                        )}

                        <div className="flex mt-1">
                          {Array.from({ length: 5 }, (_, index) => (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill={
                                index < payment.calificacion
                                  ? "currentColor"
                                  : "lightgray"
                              }
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                              style={{ marginRight: "2px" }}
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                          ))}
                        </div>
                      </div>
                      <div className="w-1/2 md:w-full md:text-xl text-right pr-5 bg-transparent">
                        <span className="text-green-700">
                          +${payment.monto.toFixed(2)}
                        </span>
                        <br />
                        <span className="text-gray-400 text-xs">
                          {/* Esta es la hora GTM-3 pero le resto tres horas porque mercadopago lo envia con GTM (3 horas despues) de aprobado */}
                          {new Date(
                            new Date(payment.hora_aprovacion_pago).getTime() -
                              3 * 60 * 60 * 1000
                          ).toLocaleString("es-AR", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              {!expandedGroups[key] && payments.length > 5 && (
                <div className="text-center">
                  <button
                    className="
              transition:all 
              duration-300 
              ease-in-out 
              mt-5 
              mb-12
              text-sm 
              md:text-lg 
              text-white 
              py-3 
              px-5 
              w-full
              md:w-1/4
               
            bg-[#FF5EA3] 
              rounded-md 
              hover:bg-[#FF7DF2] 
              hover:scale-105 "
                    onClick={() => toggleExpand(key)}
                  >
                    Mostrar Todos
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <ActivarResto
        isModalOpen={isModalOpen}
        openModal={openModal}
        closeModal={closeModal}
        cuitRestaurante={cuitRestaurante}
      />
    </div>
  );
}

export default RestauranteDashboard;
