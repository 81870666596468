// Trayendo el token de usuario del storage local
const getToken = () => sessionStorage.getItem("token");

// Fetch el token de usuario
export const fetchUserToken = async () => {
  try {
    const response = await fetch("https://tippit-qr.com:8000/userdb/token", {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${getToken()}`,
      },
    });
    
    if (!response.ok)
      throw new Error(`HTTP error! status: ${response.status}`);
    
    const data = await response.json();
    
    // chequeamos la data
    if (process.env.NODE_ENV === 'development') {
        console.log("AuthToken User token:", data);
    }
    
    return data;
    
  } catch (error) {
    console.error("Error fetching user token:", error);
    throw error; 
  }
};
