import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import MisQRS from "./pages/MisQRS";
import Turno from "./pages/Turno";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} /> 
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/misqrs" element={<MisQRS />} />
          <Route path="/turno" element={<Turno />} /> 
        </Routes>
      </Router>
    </div>
  );
}

export default App;