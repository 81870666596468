import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClockFill, DoorClosedFill, HouseFill, QrCode, ThreeDots, XLg } from 'react-bootstrap-icons';

const Menu = ({ rol, token }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [turnoActivo, setTurnoActivo] = useState(false);

  useEffect(() => {
    if (rol === 'Restaurante') {
      const turno = localStorage.getItem('Turno');
      setTurnoActivo(!!turno);
    }
  }, [rol]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleHome = (e) => {
    e.preventDefault();
    navigate('/dashboard', { state: { token } });
  };

  const handleQRS = (e) => {
    e.preventDefault();
    navigate('/misqrs', { state: { token }});
  }

  const handleLogout = (e) => {
    e.preventDefault();
    sessionStorage.clear();
    console.clear();
    navigate('/');
  };

  const handleTurno = (e) => {
    e.preventDefault();
    navigate('/turno', { state: { token, rol } });
  };

  const renderMenuItems = () => (
    <nav className="bg-gray-100 rounded">
      <ul className="space-y-2">
        <li>
          <a href="#dashboard" onClick={handleHome} className="flex items-center hover:bg-purple-500 hover:text-white p-2 rounded">
            <HouseFill className='mt-1 mr-2' aria-hidden="true" />
            <span>Inicio</span>
          </a>
        </li>
        {rol === 'Restaurante' && (
          <li className="relative">
            <a href="#turno" onClick={handleTurno} className="flex items-center hover:bg-purple-500 hover:text-white p-2 rounded block">
              <ClockFill className='mt-1 mr-2' aria-hidden="true" />
              <span>Registro de Turnos</span>
              {turnoActivo && (
                <ClockFill className="text-lg mr-1 mt-0.5 text-white bg-red-500 p-0.5 rounded-full absolute right-2 top-1 animate-blink" aria-hidden="true" />
              )}
            </a>
            <a href="#qrs" onClick={handleQRS} className="flex items-center hover:bg-purple-500 hover:text-white p-2 rounded block mt-2">
              <QrCode className='mt-2 mr-2' aria-hidden="true" />
              <span>Mis QRS</span>
            </a>
          </li>
        )}
        <li>
          <a href="#salir" onClick={handleLogout} className="flex items-center hover:bg-purple-500 hover:text-white p-2 rounded">
            <DoorClosedFill className='mt-1 mr-2' aria-hidden="true" />
            <span>Salir</span>
          </a>
        </li>
      </ul>
    </nav>
  );
  

  return (
    <div className="flex">
      <div className="hidden lg:block lg:w-64 text-gray-500 text-sm font-sans font-semibold h-full border-r-2">
        <nav className="flex flex-col p-4 space-y-2">
          {renderMenuItems()}
        </nav>
      </div>
      <div className="lg:hidden right-0 bottom-0 fixed z-50 drop-shadow-lg ">
        <button
          className="text-white bg-purple-500 p-2 rounded-full m-4"
          onClick={toggleMenu}
        >
          <ThreeDots className='text-4xl' />
        </button>
      </div>
      {isOpen && (
        <>
          {/* Background overlay */}
          <div
            className="fixed inset-0 bg-black opacity-80 z-40"
            onClick={toggleMenu}
          ></div>
          {/* Mobile menu */}
          <div
            className={`lg:hidden fixed top-0 left-0 bg-gray-100  text-gray-700 w-64 h-full z-50 transform transition-transform ease-in-out duration-3500 ${isOpen ? 'translate-x-0' : '-translate-x-full'} sliding-menu`}
          >
            <nav className="flex flex-col p-4 space-y-2">
              <button
                className="self-end text-gray-600 font-bold p-1 rounded-full"
                onClick={toggleMenu}
              >
                <XLg />
              </button>
              {renderMenuItems()}
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
