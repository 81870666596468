import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import SessionOut from "../components/SessionOut";
import {
    QrCode,
  } from "react-bootstrap-icons";

const MisQRS = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [mozos, setMozos] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleCardClick = async (mesa, mozo) => {
    try {
      const url = mozo.fin_atencion
        ? "https://tippit-qr.com:8000/mozo/activar/"
        : "https://tippit-qr.com:8000/mozo/finalizar";
        
      const params = new URLSearchParams();
      params.append("cuit", mozo.cuit);
      params.append("token_padre", mozo.token_padre);
      params.append("token_db", mozo.token_db);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        body: params.toString(),
      });

      if (!response.ok) {
        throw new Error("Error en la petición");
      }

      await fetchMozos(); // Actualizar la lista de mozos después de la petición
    } catch (error) {
      console.error("Error en el manejo del clic:", error);
    }
  };

  useEffect(() => {
    let currentToken = location.state?.token;
    if (!currentToken) {
      currentToken = sessionStorage.getItem("token");
    } else {
      sessionStorage.setItem("token", currentToken);
    }

    if (!currentToken) {
      navigate("/");
    } else {
      setToken(currentToken);
    }
  }, [navigate, location.state]);

  const fetchMozos = useCallback(async () => {
    try {
      const response = await fetch(
        "https://tippit-qr.com:8000/listar_QR_pagos",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        setSessionExpired(true);
        return;
      }

      const data = await response.json();

      if (Array.isArray(data.qr_pagos)) {
        console.table(
          data.qr_pagos.map(({ mesa, mozo }) => ({
            Nombre: mozo.nombre,
            CUIT: mozo.cuit,
            Mesa: mesa,
            FinAtencion: mozo.fin_atencion ? 'true' : 'false',
            HoraFinAtencion: mozo.hora_fin_atencion || 'No disponible',
            Foto: mozo.foto,
            TokenPadre: mozo.token_padre,
            TokenDB: mozo.token_db
          }))
        );
        setMozos(data.qr_pagos);
      }
    } catch (error) {
      console.error("Error accediendo a QR pagos:", error);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchMozos();
    }
  }, [token, fetchMozos]);

  return (
    <div className="w-full min-h-screen pb-10 bg-gradient-to-b from-pink-500 via-pink-200 to-gray-300 md:bg-none">
      <div className="flex items-left justify-left w-90 text-sm">
        <div className="flex w-full h-screen">
          <Menu rol="Restaurante" token={token} />
          <div className="flex-1 w-full">
            <h1 className="hidden md:block text-xl bg-pink-500 text-white p-2 font-sans mt-0 pt-1 pl-10 mb-4">
              Su Restaurante
            </h1>
            <div className="w-11/12 max-w-[900px] pt-8 mt-2 px-8 pb-10 min-h-96 mx-auto bg-white shadow-lg shadow-gray-250 rounded-lg relative">
              <h1 className="text-2xl font-semibold mb-2">Mis QRS</h1>
              <p className="text-md md:text-lg">
                Desde acá podés activar y desactivar los QRs de cada uno de tus moz@s.
              </p>
              <hr className="my-5 mx-[-30px]" />
              <div>
                {mozos.length > 0 ? (
                  <div className="flex flex-wrap gap-4 justify-center">
                    {mozos.map((item) => (
                      <div
                        key={item.mesa}
                        className={`bg-white shadow-lg rounded-lg p-4 w-[200px] text-center transition-transform transform hover:scale-105 hover:shadow-xl cursor-pointer select-none ${
                          item.mozo.cuit === null ? 'opacity-40 pointer-events-none ' : ''
                        }${item.mozo.fin_atencion ? 'border-4 border-white' : 'border-4 border-green-600'}`}
                        onClick={() => handleCardClick(item.mesa, item.mozo)}
                      >
                        <div className="text-left flex items-center">
                            <QrCode className="mr-2" width={20} height={20} />
                            <h2 className="text-xl font-bold">{item.mesa}</h2>
                        </div>

                        <h3 className={`text-lg text-white mb-5 rounded-md ${!item.mozo.fin_atencion ? 'bg-green-700' : 'bg-red-600'}`}>
                        {!item.mozo.fin_atencion ? 'Activo' : 'Inactivo'}
                        </h3>
                        <img
                        src={item.mozo.foto}
                        className={`rounded-full w-[120px] h-[120px] mx-auto ${item.mozo.fin_atencion ? 'grayscale' : ''}`}
                        alt={item.mozo.nombre}
                        draggable="false"
                        />

                        <label className="text-gray-700 capitalize block mt-2">
                          {item.mozo.nombre}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-center">No hay mozos vinculados.</p>
                )}
              </div>
              {sessionExpired && <SessionOut />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MisQRS;
